import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    en: {
      common:       require("./locales/en/common.json"),
      home:         require("./locales/en/pages/home.json"),
      serviceM8:    require("./locales/en/pages/serviceM8.json"),
      notFound:     require("./locales/en/pages/notFound.json"),
      jotformSubmit:require("./locales/en/pages/jotformSubmit.json"),

      storeCommon:  require("./locales/en/pages/store/common.json"),
      storeProduct: require("./locales/en/pages/store/product.json"),
      storeRedeem:  require("./locales/en/pages/store/redeem.json"),
      storeCheckout: require("./locales/en/pages/store/checkout.json"),
    },
  },
  ns: [
    "common",
    "home",
    "serviceM8",
    "notFound",
    "jotformSubmit",

    "storeCommon",
    "storeProduct",
    "storeRedeem",
    "storeCheckout",
  ],
  defaultNS: "common"
});

i18n.languages = ["en"];

export default i18n;