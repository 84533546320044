import all1 from "../assets/images/staff/all-1.png";
import all2 from "../assets/images/staff/all-2.jpg";
import all3 from "../assets/images/staff/all-3.jpg";

import { Column, SectionPage } from "phosphorus-web";
import Navbar from "../components/layout/Navbar";
import { ComponentSize, FlexDirection, Text } from "phosphorus-core";
import { Title } from "../components/text/Title";
import { useTranslation } from "react-i18next";
import BadgeContainer from "../components/display/BadgeContainer";
import { FloatingPrompt } from "../components/display/FloatingPrompt";
import { ColumnCard } from "../components/layout/ColumnCard";
import { IconShieldCheck, IconMapPin, IconNotebook, IconMail } from "@tabler/icons-react";
import {  Image, Space } from "@mantine/core";
import { BoxContainer } from "../components/display/BoxContainer";
import { EMAIL_ADDRESS } from "../_consts";
import { ImageAnimated } from "../components/display/AnimatedImage";
import Footer from "../components/layout/Footer";

export default function HomePage() {

  // Hooks
  const { t } = useTranslation("home");

  return (
    <>
      <Navbar section="hero" />

      {/* Hero section */}
      <SectionPage
        height="100vh"
      >
        <Column.Container
          direction={{ default: "Row", mobile: FlexDirection.ColumnReverse }}
          align={{ default: "Center" }}
        >
          <Column
            width={{ default: "60%", mobile: "100%" }}
            height={{ default: "100%", mobile: "55%" }}
            align={{ default: "Start" }}
            justify={{ default: "Center", mobile: "Start" }}
          >
            <Title>
              {t("hero.title")}
            </Title>
            <Text
              opacity={0.8}
            >
              {t("hero.subtitle")}
            </Text>

            <BadgeContainer
              imageHeight={{ default: 60, mobile: 40 }}
              gap={{ default: 10, mobile: 5 }}
            />
          </Column>

          <Column
            width={{ default: "40%", mobile: "100%" }}
            height={{ default: "100%", mobile: "45%" }}
            align={{ default: "Center" }}
            justify={{ default: "Center" }}
          >
            <ImageAnimated
              src={all1}
              alt="An image of the staff of Manage My Bookkeeping"
              width={100}
              radius={ComponentSize.MD}
              style={{
                position: "relative",
                top: 50,
                right: -130,
                boxShadow: "0px 10px 30px 0px rgba(0,0,0,0.2)",
                zIndex: 1,
                aspectRatio: "1/1",

              }}
            />

            <Image
              radius="md"
              src={all2}
              alt="An image of the staff of Manage My Bookkeeping"
              maw="min(200px, 50%)"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />

            <ImageAnimated
              src={all3}
              alt="An image of the staff of Manage My Bookkeeping"
              height={100}
              radius={ComponentSize.MD}
              style={{
                position: "relative",
                bottom: 50,
                left: -130,
                boxShadow: "0px 10px 30px 0px rgba(0,0,0,0.2)",
              }}
            />
          </Column>
        </Column.Container>

        <FloatingPrompt
          text={t("scrollDown", { ns: "common" })}
          show={{ default: true, mobile: false }}
        />
      </SectionPage>

      {/* Services section */}
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <a id="services" >
        <SectionPage
          backgroundColor="black"
          paddingV={40}
        >
          <Title
            color="white"
            order={2}
          >
            {t("services.title")}
          </Title>

          <Space h={10} />

          <Column.Container
            direction={{ default: FlexDirection.Row, mobile: FlexDirection.ColumnReverse }}
            align={{ default: "Center" }}
            gap={20}
          >
            <ColumnCard
              width={{ default: "33%", mobile: "100%" }}
              height={{ default: 350, mobile: "fit-content" }}
              title={t("services.cards.administration.title")}
              icon={<IconShieldCheck color="white" size={50} />}
            >
              {t("services.cards.administration.text")}
            </ColumnCard>
            <ColumnCard
              width={{ default: "33%", mobile: "100%" }}
              height={{ default: 350, mobile: "fit-content" }}
              title={t("services.cards.service.title")}
              icon={<IconMapPin color="white" size={50} />}
              highlighted={{ default: true, mobile: false }}
            >
              {t("services.cards.service.text")}
            </ColumnCard>
            <ColumnCard
              width={{ default: "33%", mobile: "100%" }}
              height={{ default: 350, mobile: "fit-content" }}
              title={t("services.cards.bookkeeping.title")}
              icon={<IconNotebook color="white" size={50} />}
            >
              {t("services.cards.bookkeeping.text")}
            </ColumnCard>
          </Column.Container>
        </SectionPage>
      </a>

      {/* About section */}
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <a id="about">
        <SectionPage
          paddingV={40}
        >
          <Column.Container
            direction={{ default: FlexDirection.Row, mobile: FlexDirection.Column }}
            align={{ default: "Center" }}
          >
            <Column
              width={{ default: "60%", mobile: "100%" }}
              height={{ default: "100%", mobile: "50%" }}
              align={{ default: "Start" }}
              justify={{ default: "Center" }}
            >
              <Title order={2}>
                {t("about.title")}
              </Title>
              <Text>
                {t("about.text.1")}
              </Text>
              <Text>
                {t("about.text.2")}
              </Text>
              <Text>
                {t("about.text.3")}
              </Text>
              <Text>
                {t("about.text.4")}
              </Text>
            </Column>

            <Column
              width={{ default: "40%", mobile: "100%" }}
              height={{ default: "100%", mobile: "50%" }}
              align={{ default: "End", mobile: "Stretch" }}
              justify={{ default: "Center" }}
            >
              <BoxContainer
                gap={5}
                rows={[
                  [
                    { text: t("about.boxes.insurance"), width: "33%" },
                    { text: t("about.boxes.tpb"), width: "66%" },
                  ],
                  [
                    { text: t("about.boxes.ato"), width: "66%" },
                    { text: t("about.boxes.xero"), width: "33%" },
                  ],
                  [
                    { text: t("about.boxes.hubdoc"), width: "100%" },
                  ],
                ]}
                width={{ default: "100%" }}
              />
            </Column>
          </Column.Container>
        </SectionPage>
      </a>

      {/* Contact section */}
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <a id="contact">
        <SectionPage
          backgroundColor="black"
          paddingV={40}
        >
          <Title
            color="white"
            order={2}
          >
            {t("contact.title")}
          </Title>

          <Space h={10} />

          <Column.Container
            direction={{ default: FlexDirection.Row, mobile: FlexDirection.ColumnReverse }}
            align={{ default: "Center" }}
            gap={20}
          >
            <ColumnCard
              width={{ default: "50%", mobile: "100%" }}
              height={{ default: 150, mobile: "fit-content" }}
              title={t("contact.cards.email.title")}
              icon={<IconMail color="white" size={50} />}
              onClick={() => window.open(`mailto:${EMAIL_ADDRESS}`)}
            >
              <Text>
                {EMAIL_ADDRESS}
              </Text>
            </ColumnCard>
          </Column.Container>
        </SectionPage>
      </a>
      <Footer />
    </>
  );
}