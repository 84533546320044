import { Box, MantineTheme, Space, Sx } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { T_ComponentSize, Text } from "phosphorus-core";
import { BaseColumnProps, BreakpointVariant, GetBreakpointValue, MOBILE_BREAKPOINT } from "phosphorus-web";
import { ReactNode } from "react";

export type ColumnCardProps = BaseColumnProps & {
  /** The title of this card */
  title: string;
  /** The icon displayed at the bottom of this card */
  icon: ReactNode;

  /** Whether this icon is "highlighted", with a custom background and slightly larger appearance */
  highlighted?: BreakpointVariant<boolean>;

  /** The size of this card's border radius. Defaults to theme default */
  borderRadius?: T_ComponentSize;
  /** The size of the internal padding. Defaults to `xs` */
  padding?: T_ComponentSize;

  /** Optional event for when the card is clicked */
  onClick?: Function;
}

export function ColumnCard(props: ColumnCardProps) {

  // Hooks
  const viewportSize = useViewportSize();
  const isMobile = viewportSize.width <= MOBILE_BREAKPOINT;
  const highlighted = GetBreakpointValue(props.highlighted, viewportSize);

  // Styles
  const containerStyle: Sx = (theme: MantineTheme) => ({
    width: GetBreakpointValue(props.width, viewportSize),
    height: GetBreakpointValue(props.height, viewportSize) || "100%",
    flexGrow: props.grow,

    display: "flex",
    flexDirection: GetBreakpointValue(props.direction, viewportSize) || "column",
    alignItems: GetBreakpointValue(props.align, viewportSize),
    justifyContent: GetBreakpointValue(props.justify, viewportSize) || "center",
    gap: props.gap !== undefined ? props.gap : theme.spacing.xs,

    border: highlighted ? null : `1px solid ${theme.colors.white[0]}30`,
    borderRadius: props.borderRadius !== undefined ? theme.radius[props.borderRadius] : theme.radius[theme.defaultRadius],
    padding: props.padding !== undefined ? theme.spacing[props.padding] : theme.spacing.xs,

    backgroundColor: highlighted ? theme.colors[theme.primaryColor][0] : null,
    background: highlighted ? null :
      `linear-gradient(${45}deg, ${theme.colors.white[0]}20, ${theme.colors.white[0]}00)`,
    transform: highlighted && !isMobile ? "scale(1.05)" : null,
  });

  return (
    <Box
      sx={containerStyle}
      style={props.style}
      onClick={!props.onClick ? null : () => props.onClick()}
    >
      <Text
        family="Plus Jakarta Sans"
        color="white"
        opacity={0.8}
        size="sm"
      >{props.title}</Text>

      <Text
        color="white"
      >
        {props.children}
      </Text>

      <Space style={{ flexGrow: 1 }} />

      {props.icon}
    </Box>
  )
}