import promoImage from "../assets/images/serviceM8-promo-1.png";

import { useTranslation } from "react-i18next"
import Navbar from "../components/layout/Navbar";
import { Column, SectionPage } from "phosphorus-web";
import { Button, ButtonVariant, ComponentSize, FlexAlign, FlexDirection, FlexJustify, Text } from "phosphorus-core";
import { Title } from "../components/text/Title";
import { Image, Space } from "@mantine/core";
import { FloatingPrompt } from "../components/display/FloatingPrompt";
import { ColumnCard } from "../components/layout/ColumnCard";
import { IconArrowUpRight, IconFlag, IconHeartHandshake } from "@tabler/icons-react";
import Footer from "../components/layout/Footer";

export default function ServiceM8Page() {

  // Hooks
  const { t } = useTranslation("serviceM8");

  return (
    <>
      <Navbar section="hero" />

      {/* Hero section */}
      <SectionPage
        height="100vh"
      >
        <Column.Container
          direction={{ default: "Row", mobile: FlexDirection.ColumnReverse }}
          align={{ default: "Center" }}
        >
          <Column
            width={{ default: "60%", mobile: "100%" }}
            height={{ default: "100%", mobile: "55%" }}
            align={{ default: "Start" }}
            justify={{ default: "Center", mobile: "Start" }}
          >
            <Title>
              {t("hero.title")}
            </Title>
            <Text
              opacity={0.8}
              size="sm"
              family="Plus Jakarta Sans"
            >
              {t("hero.subtitle")}
            </Text>
            <Text
              opacity={0.8}
            >
              {t("hero.text")}
            </Text>

            <Button
              variant={ButtonVariant.Filled}
              radius={ComponentSize.XL}
              fontFamily="Plus Jakarta Sans"
              withMotion

              onClick={() => window.open("https://sm8.link/ytun87d")}
            >
              {t("buttons.getStarted")}
            </Button>
          </Column>

          <Column
            width={{ default: "40%", mobile: "100%" }}
            height={{ default: "100%", mobile: "45%" }}
            align={{ default: "Center" }}
            justify={{ default: "Center" }}
          >
            <Image
              radius="md"
              src={promoImage}
              alt="A screenshot of ServiceM8 working on a group of desktop, tablet and mobile devices"
              maw="min(250px, 100%)"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </Column>
        </Column.Container>

        <FloatingPrompt
          text={t("scrollDown", { ns: "common" })}
          show={{ default: true, mobile: false }}
        />
      </SectionPage>

      {/* Services section */}
      <SectionPage
        backgroundColor="black"
        paddingV={40}
      >
        <Title
          color="white"
          order={2}
        >
          {t("services.title")}
        </Title>

        <Space h={10} />

        <Column.Container
          direction={{ default: FlexDirection.Row, mobile: FlexDirection.ColumnReverse }}
          align={{ default: "Center" }}
          gap={20}
        >
          <ColumnCard
            width={{ default: "33%", mobile: "100%" }}
            height={{ default: 350, mobile: "fit-content" }}
            title={t("services.cards.start.title")}
            icon={<IconFlag color="white" size={50} />}
          >
            {t("services.cards.start.text")}
          </ColumnCard>
          <ColumnCard
            width={{ default: "33%", mobile: "100%" }}
            height={{ default: 350, mobile: "fit-content" }}
            title={t("services.cards.train.title")}
            icon={<IconArrowUpRight color="white" size={50} />}
            highlighted={{ default: true, mobile: false }}
          >
            {t("services.cards.train.text")}
          </ColumnCard>
          <ColumnCard
            width={{ default: "33%", mobile: "100%" }}
            height={{ default: 350, mobile: "fit-content" }}
            title={t("services.cards.achieve.title")}
            icon={<IconHeartHandshake color="white" size={50} />}
          >
            {t("services.cards.achieve.text")}
          </ColumnCard>
        </Column.Container>

        <Space h={10} />

        <Column
          width={{ default: "100%" }}
          align={{ default: FlexAlign.Center }}
          justify={{ default: FlexJustify.Center }}
        >
          <Button
            variant={ButtonVariant.Filled}
            radius={ComponentSize.XL}
            fontFamily="Plus Jakarta Sans"
            withMotion

            onClick={() => window.open("https://sm8.link/ytun87d")}
          >
            {t("buttons.getStarted")}
          </Button>
        </Column>
      </SectionPage>

      <Footer />
    </>
  )
}