import { Image } from "@mantine/core";
import { useReducedMotion } from "@mantine/hooks";
import { motion, useMotionValue, useTime } from "framer-motion";
import { T_ComponentSize } from "phosphorus-core";
import { CSSProperties, useEffect } from "react";

export type ImageAnimatedProps = {
  /** The source of the image */
  src: string;
  /** The alt text of the image */
  alt: string;

  /** The height of the image */
  height?: string | number;
  /** The width of the button */
  width?: string | number;

  /** Border radius of the image */
  radius?: T_ComponentSize;

  /** Optional style parameters to override */
  style?: CSSProperties;
}

export function ImageAnimated(props: ImageAnimatedProps) {
  const isReducedMotion = useReducedMotion();

  const time = useTime();
  const timeOffset = Math.random() * 10;
  const position = useMotionValue(0);

  useEffect(() => {
    return time.onChange((latest) => {
      position.set(Math.sin(timeOffset + (latest / 500)) * 5);
    })
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <motion.div
      // whileHover={{ scale: isReducedMotion ? 1 : 1.05 }}
      // whileTap={{ scale: isReducedMotion ? 1 : 1.02 }}
      style={{ y: isReducedMotion ? 0 : position, ...props.style }}
    >
      <Image
        src={props.src}
        alt={props.alt}
        height={props.height}
        width={props.width}
        radius={props.radius}
      />
    </motion.div>
  )
}