import { Image, MantineTheme, Sx, UnstyledButton } from "@mantine/core";
import { motion, useReducedMotion } from "framer-motion";
import { CSSProperties } from "react";

export type ButtonImageProps = {
  /** The source of the image */
  imageSrc: string;
  /** The alt text of the image */
  imageAlt: string;

  /** The height of this button */
  height?: number | string;
  /** The width of this button */
  width?: number | string;

  /** A JS action to take upon clicking the button */
  onClick?: Function;
  /** Polymorphic content to allow this button to become another element */
  component?: any;
  /** Used by react-router-dom */
  to?: string;
  /** Used to link to external sites */
  href?: string;
  /** Used if links should open in new tabs */
  target?: string;

  /** Whether this button is disabled */
  disabled?: boolean;

  /** Optional styles to override */
  style?: CSSProperties;
  /** Optional styles to override on the image */
  imageStyle?: CSSProperties;
}

export function ButtonImage(props: ButtonImageProps) {

  // Hooks
  const isReducedMotion = useReducedMotion();
  const shouldHover = props.onClick !== undefined || props.to !== undefined || props.href !== undefined;

  // Styles
  const buttonStyle: Sx = (theme: MantineTheme) => ({ 
    width: "100%",
    height: "100%",
    cursor: shouldHover ? "pointer" : "default",
  })

  return (
    <motion.div
      whileHover={{ scale: isReducedMotion || !shouldHover ? 1 : 1.1 }}
      whileTap={{ scale: isReducedMotion || !shouldHover ? 1 : 1.05 }}
      style={{ width: props.width, height: props.height }}
      transition={{ ease: "backOut" }}
    >
      <UnstyledButton
        onClick={props.onClick && !props.disabled ? props.onClick : null}
        component={props.component}
        to={props.to}
        href={props.href}
        target={props.target}

        sx={buttonStyle}
        style={props.style}
      >
        <Image
          src={props.imageSrc}
          alt={props.imageAlt}
          width={props.width}
          height={props.height}
          miw={80}
          fit="contain"
          withPlaceholder

          style={props.imageStyle}
        />
      </UnstyledButton>
    </motion.div>
  )
}