import basAgentWide from "../../assets/images/badges/basagent-wide.png";
import xeroWide from "../../assets/images/badges/xero-wide.png";
import hubdocWide from "../../assets/images/badges/hubdoc-wide.png";
import serviceM8 from "../../assets/images/badges/servicem8.png";
import stpWide from "../../assets/images/badges/stp-wide.png";
import icbWide from "../../assets/images/badges/icb-wide.png";

import { Group } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { BreakpointVariant, GetBreakpointValue } from "phosphorus-web";
import { ButtonImage } from "../buttons/ButtonImage";
import { Link } from "react-router-dom";

type Props = {
  imageHeight: BreakpointVariant<number>;
  gap?: BreakpointVariant<number>;
}

export default function BadgeContainer(props: Props) {

  // Hooks
  const viewportSize = useViewportSize();
  const imageHeight = GetBreakpointValue(props.imageHeight, viewportSize);

  return (
    <Group
      spacing={props.gap ? GetBreakpointValue(props.gap, viewportSize) : "xs" }
    >
      <ButtonImage
        imageSrc={basAgentWide}
        imageAlt="Badge for the registered BAS Agent #25478363 on the Tax Practitioners Board"
        height={imageHeight}
        component={Link}
        to="https://www.tpb.gov.au/public-register?practitioner=25478363"
        target="_blank"
      />
      <ButtonImage
        imageSrc={icbWide}
        imageAlt="Badge for the Institute of Certified Bookkeepers"
        height={imageHeight}
        component={Link}
        to="https://www.icb.org.au/"
        target="_blank"
      />
      <ButtonImage
        imageSrc={xeroWide}
        imageAlt="Badge for Xero Silver Partner"
        height={imageHeight}
      />
      <ButtonImage
        imageSrc={hubdocWide}
        imageAlt="Badge for Hubdoc Certified Advanced Partner"
        height={imageHeight}
      />
      <ButtonImage
        imageSrc={serviceM8}
        imageAlt="Badge for partnership with ServiceM8"
        height={imageHeight}
        component={Link}
        to="/serviceM8"
      />
      <ButtonImage
        imageSrc={stpWide}
        imageAlt="Badge for Xero Silver Partner Single Touch Payroll"
        height={imageHeight}
      />
    </Group>
  )
}