import faviconWhite from "../../assets/images/favicon-white.png";

import { Group, Image } from "@mantine/core";
import { FlexAlign, FlexDirection, FlexJustify, Text } from "phosphorus-core";
import { Column, SectionPage } from "phosphorus-web";
import { useTranslation } from "react-i18next";
import { Title } from "../text/Title";
import { EMAIL_ADDRESS } from "../../_consts";
import { Link } from "react-router-dom";

export default function Footer() {

  // Hooks
  const { t } = useTranslation();

  return (
    <SectionPage
      backgroundColor="black"
    >
      <Column.Container
        direction={{ default: FlexDirection.Row, mobile: FlexDirection.Column }}
      >
        <Column
          width={{ default: "33%", mobile: "100%" }}
          align={{ default: FlexAlign.Start, mobile: FlexAlign.Center }}
          justify={{ default: FlexJustify.Center }}
        >
          <Group>
            <Image
              src={faviconWhite}
              height={40}
              width="auto"
            />
            <Text
              color="white"
              family="Plus Jakarta Sans"
              weight={800}
            >
              {t("name")}
            </Text>
          </Group>
        </Column>
        <Column
          width={{ default: "33%", mobile: "100%" }}
          align={{ default: FlexAlign.Start }}
          justify={{ default: FlexJustify.Start }}
        >
          <Title
            order={6}
            color="white"
          >
            {t("footer.contact.title")}
          </Title>

          <Text
            size="sm"
            opacity={0.8}
            color="white"
            // @ts-ignore
            onClick={() => window.open(`mailto:${EMAIL_ADDRESS}`)}
          >
            {t("footer.contact.email", { email: EMAIL_ADDRESS })}
          </Text>
        </Column>
        <Column
          width={{ default: "33%", mobile: "100%" }}
          align={{ default: FlexAlign.Start }}
          justify={{ default: FlexJustify.Start }}
        >
          <Title
            order={6}
            color="white"
          >
            {t("footer.partners.title")}
          </Title>

          <Text
            size="sm"
            opacity={0.8}
            color="white"
            //@ts-ignore
            component={Link}
            to="/serviceM8"
          >
            {t("footer.partners.serviceM8")}
          </Text>
          <Text
            size="sm"
            opacity={0.8}
            color="white"
          >
            {t("footer.partners.xero")}
          </Text>
          <Text
            size="sm"
            opacity={0.8}
            color="white"
          >
            {t("footer.partners.quickbooks")}
          </Text>
          <Text
            size="sm"
            opacity={0.8}
            color="white"
            //@ts-ignore
            component="a"
            href="https://www.tpb.gov.au/public-register?practitioner=25478363"
            target="_blank"
          >
            {t("footer.partners.tpb")}
          </Text>
        </Column>
      </Column.Container>
    </SectionPage>
  )
}