import { MantineTheme, Stack, Sx } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { IconChevronCompactDown } from "@tabler/icons-react";
import { Text } from "phosphorus-core";
import { BreakpointVariant, GetBreakpointValue } from "phosphorus-web";
import { CSSProperties, ReactNode } from "react";

export type FloatingPromptProps = {
  /** Text to display in the prompt */
  text: string;
  /** An icon to display underneath the prompt */
  icon?: ReactNode;

  /** The distance the prompt should sit from the bottom of the parent container */
  bottom?: number | string;
  /** The gap between components within the prompt */
  gap?: number;

  /** Whether or not to show the prompt */
  show?: BreakpointVariant<boolean>;

  /** Optional style parameters to override */
  style?: CSSProperties;
}

export function FloatingPrompt(props: FloatingPromptProps) {

  // Hooks
  const viewportSize = useViewportSize();

  // Styles
  const containerStyle: Sx = (theme: MantineTheme) => ({
    position: "relative",
    bottom: props.bottom !== undefined ? props.bottom : 20,
  });

  return (
    GetBreakpointValue(props.show, viewportSize) === false ? null :
      <Stack
        align="center"
        sx={containerStyle}
        style={props.style}
        spacing={props.gap !== undefined ? props.gap : 0}
      >
        <Text>{props.text}</Text>
        {props.icon ? props.icon :
          <IconChevronCompactDown />
        }
      </Stack>
  )
}