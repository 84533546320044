import { Center, Stack } from "@mantine/core";
import { Title } from "../components/text/Title";
import { useTranslation } from "react-i18next";
import { Button, ComponentSize, Text } from "phosphorus-core";
import { Link } from "react-router-dom";

export default function NotFoundPage() {

  // Hooks
  const { t } = useTranslation("notFound");

  return (
    <>
      <Center h="100vh">
        <Stack>
          <Title align="center" order={2}>{t("title")}</Title>
          <Text align="center" opacity={0.8}>{t("description")}</Text>
          <Button
            radius={ComponentSize.XL}
            component={Link}
            to="/"
          >
            {t("link")}
          </Button>
        </Stack>
      </Center>
    </>
  )
}