import plusJakartaReg from "./assets/fonts/PlusJakartaSans-Regular.ttf";
import plusJakartaItalic from "./assets/fonts/PlusJakartaSans-Italic.ttf";
import plusJakartaExtraBold from "./assets/fonts/PlusJakartaSans-ExtraBold.ttf";
import plusJakartaExtraBoldItalic from "./assets/fonts/PlusJakartaSans-ExtraBoldItalic.ttf";
import ibmPlexSansReg from "./assets/fonts/IBMPlexSans-Regular.ttf";
import ibmPlexSansItalic from "./assets/fonts/IBMPlexSans-Italic.ttf";

import { Global, MantineProvider } from "@mantine/core";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { COLORS } from "./components/_colors";
import HomePage from "./pages/Home";
import ServiceM8Page from "./pages/ServiceM8";
import NotFoundPage from "./pages/404";
import { Notifications } from "@mantine/notifications";
import ScrollToTop from "./components/display/ScrollToTop";

function App() {
  return (
    <>
      <Global
        styles={[
          {
            '@font-face': {
              fontFamily: 'Plus Jakarta Sans',
              src: `url('${plusJakartaReg}') format("woff2")`,
              fontWeight: 400,
              fontStyle: 'normal',
            },
          },
          {
            '@font-face': {
              fontFamily: 'Plus Jakarta Sans',
              src: `url('${plusJakartaItalic}') format("woff2")`,
              fontWeight: 400,
              fontStyle: 'italic',
            },
          },
          {
            '@font-face': {
              fontFamily: 'Plus Jakarta Sans',
              src: `url('${plusJakartaExtraBold}') format("woff2")`,
              fontWeight: 800,
              fontStyle: 'normal',
            },
          },
          {
            '@font-face': {
              fontFamily: 'Plus Jakarta Sans',
              src: `url('${plusJakartaExtraBoldItalic}') format("woff2")`,
              fontWeight: 800,
              fontStyle: 'italic',
            },
          },
          {
            '@font-face': {
              fontFamily: 'IBM Plex Sans',
              src: `url('${ibmPlexSansReg}') format("woff2")`,
              fontWeight: 400,
              fontStyle: 'normal',
            },
          },
          {
            '@font-face': {
              fontFamily: 'IBM Plex Sans',
              src: `url('${ibmPlexSansItalic}') format("woff2")`,
              fontWeight: 400,
              fontStyle: 'italic',
            },
          },
        ]}
      />

      <BrowserRouter>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            fontFamily: "IBM Plex Sans, sans-serif",
            headings: { fontFamily: "Plus Jakarta Sans, sans-serif", fontWeight: 800 },
            colorScheme: "light",
            colors: {
              //@ts-ignore
              black: COLORS.BLACK,
              //@ts-ignore
              white: COLORS.WHITE,
              //@ts-ignore
              cyan: COLORS.CYAN,
            },
            black: COLORS.BLACK[0],
            white: COLORS.WHITE[0],
            primaryColor: "cyan",
            primaryShade: 4,
            defaultRadius: "md",
            lineHeight: 1.25,
          }}
        >
          <Notifications />
          <ScrollToTop />

          <Routes>
            <Route path="/">
              <Route index element={<HomePage />} />
              <Route path="serviceM8" element={<ServiceM8Page />} />
              {/* <Route path="jotform-submit" element={<JotformSubmitPage />} /> */}
              {/* <Route path="store/*" element={<StoreIndexPage />} /> */}
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </MantineProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
