import { Box, Group, Stack } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { Text } from "phosphorus-core";
import { BreakpointVariant, GetBreakpointValue } from "phosphorus-web";
import { CSSProperties } from "react";

export type BoxContainerItem = {
  /** Text content of this item */
  text: string;
  /** The width of this item */
  width?: string | number;
  /** The color of this item. Defaults to theme primary color */
  color?: string;
  /** The color of the text in this item. Defaults to theme `white` color */
  textColor?: string;

  /** Optional style parameters to overrride */
  style?: CSSProperties;
}

export type BoxContainerProps = {
  /** Rows of content for this container */
  rows: BoxContainerItem[][];
  /** The width of this container */
  width?: BreakpointVariant<number | string>;
  /** The gap between rows and items. Defaults to `theme.spacing.xs` */
  gap?: number | string;

  /** Optional style parameters to override */
  style?: CSSProperties;
}

export function BoxContainer(props: BoxContainerProps) {

  // Hooks
  const viewportSize = useViewportSize();

  // Styles
  const containerStyle: CSSProperties = {
    width: GetBreakpointValue(props.width, viewportSize),
  }
  const groupStyle: CSSProperties = { 
    flexFlow: "nowrap",
  }
  const boxStyle: CSSProperties = {
    padding: 10,
    borderRadius: 5,
    height: 100,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }

  return (
    <Stack
      spacing={props.gap !== undefined ? props.gap : "xs"}
      style={{
        ...containerStyle,
        ...props.style,
      }}
    >
      {props.rows.map((row, i) =>
        <Group
          key={i}
          spacing={props.gap !== undefined ? props.gap : "xs"}
          style={groupStyle}
        >
          {row.map((item, j) =>
            <Box
              key={`${i}${j}`}
              sx={(theme) => ({
                backgroundColor: item.color !== undefined ?
                  theme.colors[item.color][0] :
                  theme.colors[theme.primaryColor][0],
                width: item.width !== undefined ? item.width : "50%"
              })}
              style={{
                ...boxStyle,
                ...item.style,
              }}
            >
              <Text
                color={item.textColor !== undefined ? item.textColor : "white"}
                size="sm"
                align="center"
              >
                {item.text}
              </Text>
            </Box>
          )}
        </Group>
      )}
    </Stack>
  )
}