import favicon from "../../assets/images/favicon-default.png";

import { useTranslation } from "react-i18next";
import { NavBase } from "phosphorus-web";
import { Button, ButtonVariant, ComponentSize } from "phosphorus-core";
// import { Link } from "react-router-dom";

type Props = {
  section?: "hero" | "services" | "about" | "store" | "contact";
}

export default function Navbar(props: Props) {

  // Hooks
  const { t } = useTranslation();

  return (
    <NavBase
      backgroundOnScroll
      background="white"
      favicon={favicon}
      faviconButtonProps={{
        component: "a",
        href: "/#",
        children: "",
      }}
      typeface={t("name")}
      typefaceFamily="Plus Jakarta Sans"
      gap={5}
      buttons={[
        <Button
          key={0}
          radius={ComponentSize.XL}
          variant={ButtonVariant.Subtle}
          color="black"
          fontFamily="Plus Jakarta Sans"

          component="a"
          href="/"
        >
          {t("navbar.buttons.home")}
        </Button>,
        <Button
          key={0}
          radius={ComponentSize.XL}
          variant={props.section === "services" ? ButtonVariant.Light : ButtonVariant.Subtle}
          color="black"
          fontFamily="Plus Jakarta Sans"

          component="a"
          href="/#services"
        >
          {t("navbar.buttons.services")}
        </Button>,
        <Button
          key={1}
          radius={ComponentSize.XL}
          variant={props.section === "about" ? ButtonVariant.Light : ButtonVariant.Subtle}
          color="black"
          fontFamily="Plus Jakarta Sans"

          component="a"
          href="/#about"
        >
          {t("navbar.buttons.about")}
        </Button>,
        // <Button
        //   key={2}
        //   radius={ComponentSize.XL}
        //   variant={props.section === "store" ? ButtonVariant.Light : ButtonVariant.Subtle}
        //   color="black"
        //   fontFamily="Plus Jakarta Sans"

        //   component={Link}
        //   to="/store"
        // >
        //   {t("navbar.buttons.store")}
        // </Button>,
        <Button
          key={3}
          radius={ComponentSize.XL}
          variant={ButtonVariant.Filled}
          fontFamily="Plus Jakarta Sans"
          withMotion
          withShadow

          component="a"
          href="/#contact"
        >
          {t("navbar.buttons.contact")}
        </Button>
      ]}
    />
  );
}